/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W sierpniu 2024 roku została podpisana umowa na zadanie inwestycyjne pn. „Rozbudowa drogi gminnej nr 150812C w miejscowości Skotniki”."), "\n", React.createElement(_components.p, null, "W ramach prac budowlanych zostaną zrealizowane między innymi następujące zadania:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "rozbudowa układu drogowego dróg gminnych w miejscowości Skotniki,"), "\n", React.createElement(_components.li, null, "budowa kanału technologicznego,"), "\n", React.createElement(_components.li, null, "przebudowa kolidującej infrastruktury wodociągowej,"), "\n", React.createElement(_components.li, null, "przebudowa kolidujących elementów systemu melioracji"), "\n", React.createElement(_components.li, null, "przebudowa kolidującej infrastruktury elektroenergetycznej,"), "\n", React.createElement(_components.li, null, "wykonanie drogi gminnej o nawierzchni bitumicznej"), "\n"), "\n", React.createElement(_components.p, null, "Termin realizacji przewidywany jest na 4 miesiące od daty podpisania umowy. Inwestycja planowo powinna być oddana do użytku pod koniec roku 2024."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
